const endPoint =
  "https://prod-in2.100ms.live/hmsapi/vaman-audioroom-1143.app.100ms.live/";
const room_id = "6512bfa6cb39d57e8a5d22f6";

export default async function getToken(role) {
  const response = await fetch(`${endPoint}api/token`, {
    method: "POST",
    body: JSON.stringify({
      user_id: `${Date.now()}`, // User ID assigned by you (different from 100ms' assigned id)
      role: role, // listener , speaker , moderator
      room_id,
    }),
  });

  const { token } = await response.json();

  return token;
}
