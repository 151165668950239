import React from 'react';

const Select = ({ state }) => {
  return (
    <select
      className="role-selector text-center py-3 mb-6 w-80 bg-gray-100 focus:bg-black rounded-3xl border-none focus:ring-1 ring-brand-100 outline-none text-gray-200 appearance-none"
      value={state.role}
      name="Choose Roles"
      onChange={(e) => state.setRole(e.target.value)}
    >
      <option value="listener">Listener (can only listen)</option>
      <option value="speaker">Speaker (can speak & listen) </option>
      <option value="moderator">Moderator (is literal god)</option>
    </select>
  );
};

export default Select;
